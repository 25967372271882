* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  color: #000000;
}

.beckn-app {
  /* background: url("./assets/experience_bg.svg");
  background-size: cover; */
  background: rgb(243, 243, 243);
  background: linear-gradient(
    90deg,
    rgba(243, 243, 243, 1) 0%,
    rgba(211, 234, 251, 1) 100%
  );
  position: relative;
  min-height: 100vh;
  height: max-content;
  display: grid;
}

.Title {
  font-style: normal;
  color: white;
  font-weight: 275;
}
/* -----------------Card.tsx-------------------------------- */
.image-url-containers img:nth-child(2) {
  display: none;
}

.image-url-containers:hover img:nth-child(1) {
  display: none;
}

.image-url-containers:hover img:nth-child(2) {
  display: block;
}
.image-url-containers img:nth-child(2) {
  display: none;
}
.hover_card:hover .image-url-containers {
  display: flex;
  justify-content: center;
}
.hover_card:hover .image-url-containers img:nth-child(1) {
  display: none;
}
.hover_card:hover .image-url-containers img:nth-child(2) {
  display: block;
}
iframe .header-container .location-btn {
  padding: 10px 10px !important;
}
.ant-tabs-nav {
  margin: 0 auto !important;
  margin-top: 45px !important;
}
.videoScreen {
  margin-top: 45px !important;
}
.tabs-style {
  margin-top: 45px;
  margin-bottom: 60px !important;
}
/* ----------------------------Media Query---------------------- */
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .headLogo-image {
    margin-top: 20px !important;
  }
  .home-buttom-nevigate {
    top: 20px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1224px) {
  .page-content {
    height: 100%;
    width: 100%;
  }
  .card-head {
    width: 30%;
    margin: 15px;
  }
  .image-url-containers {
    margin: unset !important;
    width: 100%;
  }
  .firstImage {
    width: 100%;
  }
  .secondImage {
    width: 100%;
  }
  .child-container {
    height: 100%;
  }
  .videoScreen {
    width: 900px !important;
    height: 550px !important;
    margin-top: 43px !important;
  }
}

@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: portrait) {
  .videoScreen {
    width: 725px !important;
    height: 408px !important;
  }
  .exit-icon {
    bottom: 467px !important;
    right: 25px !important;
  }
}
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: landscape) {
  .tab-wrappper-content {
    width: 75% !important;
  }
  .card-container {
    width: 1000px !important;
  }
}
@media screen and (min-width: 1225px) and (max-width: 1800px) {
  .beckn-app {
    margin: 0 auto;
    width: 100%;
    min-height: 100vh;
    height: max-content;
  }
}
