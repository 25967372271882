.selectionPage_wrapper {
  padding: 20px;
}
.beckn_logo {
  width: 87px;
  height: 27px;
  margin: 56px 0 20px;
}
.beckn_logo img {
  width: 100%;
}
.selectionPage_wrapper_innr a {
  text-decoration: none;
}
.Link_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 95px;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.03);
  padding-right: 15px;
}
.link_img {
  height: 95px;
  width: 85px;
  background-color: #252525;
  display: grid;
  align-content: center;
  place-items: center;
  border-radius: 10px;
}
