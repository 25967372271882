.tab-wrappper-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 0px auto;
}
.ant-tabs-nav {
  width: 1080px;
  height: 86px;
  background: #ffffff;
  box-shadow: 8px 10px 42px rgba(0, 0, 0, 0.42);
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  /* background: linear-gradient(
    to top,
    white 0%,
    white 50%,
    #DCDCDC 50%,
    #DCDCDC 100%
  ); */
}
:where(.css-dev-only-do-not-override-ixblex).ant-tabs
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin: unset !important;
}
.ant-tabs-tab {
  padding: 15px 30px !important;
}
.ant-tabs-tab {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 100%;
  border-left: 0.5px solid #c7c7c7 !important;
}
/* The device with borders */
.smartphone-wrapper {
  position: relative;
  width: 393px;
  height: 686px;
  border: 4px solid #ffffff;
  border-radius: 36px;
}
.smartphone {
  position: relative;
  width: 360px;
  height: 640px;
  margin: auto;
  border: 4px #d9d9d9 solid;
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-radius: 36px;
  margin-top: 1px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #d9d9d9;
  border-radius: 50%;
  z-index: 99;
}

/* The screen (or content) of the device */
.smartphone {
  width: 383px;
  height: 678px;
  background: white;
}
.content {
  width: 258px;
  height: 520px;
}
.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 10px solid #77aede !important;
  z-index: 99;
}
.ant-tabs-tab.ant-tabs-tab-active[data-node-key="6"] {
  border-bottom-width: 10px !important;
  padding: 34px !important;
}
.ant-tabs-ink-bar {
  border-bottom: 10px solid #77aede !important;
}
.ant-tabs-ink-bar[data-node-key="6"] {
  width: 60px !important;
}
.ant-tabs-ink-bar[data-node-key="6"] {
  border-bottom-right-radius: 36px !important;
}
@media screen and (min-width: 768px) and (max-width: 1080px) {
  .ChooseExpIframe {
    height: 617px !important;
  }
  .smartphone-wrapper {
    height: 636px !important;
  }
  .smartphone {
    height: 628px !important;
  }
  .ant-tabs-nav {
    width: 740px;
  }
  .ant-tabs-tab {
    font-size: 12px !important;
  }
  .ant-tabs-tab {
    padding: 5px 10px !important;
  }
  .tab-wrappper-content img {
    width: 100%;
  }
}
